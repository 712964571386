<template>
<CModalExtended
  :title="getTitulo"
  color="dark"
  size="xl"
  :close-on-backdrop="false"
  :show.sync="modalActive"
  class="modal-content-linea"
>
  <form @submit.stop.prevent="submit" class="company-form">

     <CRow>
      <CCol sm="12">
        <CCard class="mb-0">
          <CCardBody>
            <CRow align-vertical="center" class="mt-1">
              <CCol sm="4">
                <div class="preview">    
                  <div class="preview position-relative">    
                    <picture-input
                      v-if="modalActive"
                      ref="imageInput"
                      :imgSrc="image"
                      width="200"
                      height="200"
                      accept="image/jpeg,image/png"
                      size="10"
                      :custom-strings="imgInputTexts"
                      @change="handleFileUpload"
                    >
                    </picture-input>
                </div>
                </div>
                <div v-if="$v.linea.LineRoute.$error">
                  <div class='text-invalid-feedback' v-if="!$v.linea.LineRoute.isValidFile">{{$t('label.badImage')}}</div>
                </div>
              </CCol>
              <CCol sm="8">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.linea.ShippingLineName.$model"
                      v-uppercase
                      :invalid-feedback="errorMessage($v.linea.ShippingLineName)"
                      :is-valid="hasError($v.linea.ShippingLineName)"
                      size="sm"
                      :label="$t('label.name')"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      add-label-classes="required text-right"
                      maxlength="50"
                      :placeholder="$t('label.newShippingLineName')" 
                    />
                  </CCol>
                  <CCol sm="12"> 
                    <CInput
                      v-model.trim="$v.linea.PrimaryPhone.$model"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      :invalid-feedback="errorMessage($v.linea.PrimaryPhone)" 
                      :is-valid="hasError($v.linea.PrimaryPhone)"
                      size="sm"
                      type="tel"
                      :label="$t('label.mainPhone')"
                      add-label-classes="text-right"
                      maxlength="15"
                      minlength="11"
                      :placeholder="$t('label.mainPhone')" 
                    />
                  </CCol>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.linea.SecondaryPhone.$model"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      :invalid-feedback="errorMessage($v.linea.SecondaryPhone)" 
                      :is-valid="hasError($v.linea.SecondaryPhone)"
                      size="sm"
                      type="tel"
                      :label="$t('label.secondaryPhone')"
                      add-label-classes="text-right"
                      maxlength="15"
                      minlength="11"
                      :placeholder="$t('label.secondaryPhone')" 
                    />
                  </CCol><!-- :invalid-feedback="$t('label.email')" addLabelClasses="text-right"-->
                  <CCol sm="12">
                     <CInput
                      v-model.trim="$v.linea.PrimaryEmail.$model"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      :is-valid="hasError($v.linea.PrimaryEmail)"
                      size="sm"
                      :label="$t('label.mainEmail')"
                      maxlength="100"
                      v-uppercase
                      :placeholder="$t('label.mainEmail')"   
                      :invalid-feedback="errorMessage($v.linea.PrimaryEmail)"
                      add-label-classes="text-right"
                      v-model="linea.PrimaryEmail"
                    />
                  </CCol>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.linea.SecondaryEmail.$model"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      :is-valid="hasError($v.linea.SecondaryEmail)"
                      size="sm"
                      :label="$t('label.secondaryEmail')"
                      maxlength="100"
                      v-uppercase
                      :placeholder="$t('label.secondaryEmail')"
                      :invalid-feedback="errorMessage($v.linea.SecondaryEmail)"
                      add-label-classes="text-right"
                      v-model="linea.SecondaryEmail"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="4">
                <CRow >
                  <CCol sm="12">
                     <CInput
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      :label="$t('label.color')"
                      add-label-classes="required text-right"  
                      v-uppercase
                      type="color"
                      v-model="$v.linea.Color.$model"
                      :is-valid="hasError($v.linea.Color)"
                      :invalid-feedback="errorMessage($v.linea.Color)" 
                      required
                    />
                  </CCol>
                  <CCol sm="12">
                      <CInput
                      v-model.trim="$v.linea.ShippingLineAbbrev.$model"
                      v-uppercase
                      :invalid-feedback="errorMessage($v.linea.ShippingLineAbbrev)" 
                      :is-valid="hasError($v.linea.ShippingLineAbbrev)"
                      size="sm"
                      :label="$t('label.acronym')"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      add-label-classes="required text-right"
                      maxlength="8"
                      :placeholder="$t('label.acronym')" 
                    />
                    </CCol>
                </CRow>
              </CCol>
              <CCol sm="8">
                <CRow>
                  <CCol sm="12">
                    <CTextarea
                      v-model="$v.linea.Address.$model"
                      :invalid-feedback="errorMessage($v.linea.Address)" 
                      :is-valid="hasError($v.linea.Address)"
                      size="sm"
                      :label="$t('label.address')"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      add-label-classes="required text-right"
                      maxlength="150"
                      :placeholder="$t('label.newShippingLineAddress')" 
                    />
                  </CCol> 
                </CRow>
                <CRow>
                  <CCol>
                    <CSelect
                      :value.sync="linea.Status"
                      :is-valid="statusSelectColor"
                      size="sm"
                      :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                      :label="$t('label.status')"
                      :options="statusOptions"
                      add-label-classes="text-right"
                      class="mt-2"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow> 
        
  </form>
  <template #footer>
    <CButton
      color="add"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="statusConfirmation"
    >
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
    </CButton>
    <CButton
      color="wipe"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import LineaValidations from '@/_validations/linea-naviera/LineaNavieraValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import LineaNavieraMixin from '@/_mixins/linea-naviera';
import { GenerateImgPlaceholder } from '@/_helpers/funciones';
import PictureInput from '@/components/picture-input';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    titulo: '',
    linea: {
      ShippingLineId: '',
      ShippingLineName: '',
      ShippingLineAbbrev: '',
      Address: '',
      PrimaryPhone: '',
      SecondaryPhone: '',
      PrimaryEmail: '',
      SecondaryEmail: '',
      LineRoute: '',
      Color: '#FFF',
      Status: 0
    },
    image: ''
  }
}

//Methods
function submitFile() {
  try {
    this.isSubmit = true;
    this.$v.linea.$touch();

    if (this.$v.linea.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    if(this.lineaData.LineRoute == this.linea.LineRoute)
      this.submit();
    else{
      this.isSubmit = true;
    
      this.$http.file('shipping-agency-update', this.linea.LineRoute, this.lineaData.LineRoute)
      .then((response) => {
        this.linea.LineRoute = response.data.data.files[0].path;
        this.submit();
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.isSubmit = false;
      });
    }
  } catch (e) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }

}
function submit() {

  try {
    this.isSubmit = true; 
    this.$v.linea.$touch();

    if (this.$v.linea.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    this.$v.linea.$touch();
    if (this.$v.linea.$pending || this.$v.linea.$error) return;

    this.linea.PrimaryPhone.replace('-', '');
    this.linea.SecondaryPhone.replace('-', '');
    
    let LineJson = {
      ShippingLineId    : this.linea.ShippingLineId,
      ShippingLineName  : this.linea.ShippingLineName,
      ShippingLineAbbrev: this.linea.ShippingLineAbbrev, 
      Address           : this.linea.Address, 
      PrimaryPhone      : this.linea.PrimaryPhone, 
      SecondaryPhone    : this.linea.SecondaryPhone, 
      PrimaryEmail      : this.linea.PrimaryEmail, 
      SecondaryEmail    : this.linea.SecondaryEmail, 
      LineRoute         : this.linea.LineRoute,
      Color             : this.linea.Color,
      Status            : this.linea.Status
    };
    
    this.$http.put("shipping-agency-update", LineJson, { root: 'LineJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: response.data.data[0].Response,
        type: "success"
      });

      this.$store.commit('planificacionEstibaErrores/set', true);
      this.resetForm();
      
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.toggle(false);
    });
  } catch (e) {
    this.$notify({  
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error" });
  }
}
function toggle(newVal) {
  if(!newVal)
    this.resetForm();
    
  this.modalActive = newVal;
}
function setLineaData(newLinea) {
  this.image = newLinea.LineRoute
    ?`${this.$store.getters["connection/getBase"]}${newLinea.LineRoute.replace('public/', '', null, 'i')}`
    :GenerateImgPlaceholder();
  
  this.linea.ShippingLineId = newLinea.ShippingLineId;
  this.linea.ShippingLineName = newLinea.ShippingLineName;
  this.linea.ShippingLineAbbrev = newLinea.ShippingLineAbbrev;
  this.linea.Address = newLinea.Address;
  this.linea.PrimaryPhone = newLinea.PrimaryPhone;
  this.linea.SecondaryPhone = newLinea.SecondaryPhone;
  this.linea.PrimaryEmail = newLinea.PrimaryEmail;
  this.linea.SecondaryEmail = newLinea.SecondaryEmail;
  this.linea.LineRoute = newLinea.LineRoute;
  this.linea.Color = newLinea.Color;
  this.linea.Status = newLinea.FgActShippingLine?1:0;
  this.titulo = this.$t('label.edit')+' '+this.$t('label.newShippingLine')+': '+this.linea.ShippingLineName;
  this.$v.linea.$touch();
  //this.touchRequiredValues();
}
function resetForm() {
  this.linea.ShippingLineId = '';
  this.linea.ShippingLineName = '';
  this.linea.ShippingLineAbbrev = '';
  this.linea.Address = '';
  this.linea.PrimaryPhone = '';
  this.linea.SecondaryPhone = '';
  this.linea.PrimaryEmail = '';
  this.linea.SecondaryEmail = '';
  this.linea.LineRoute = '';
  this.linea.Color = '#FFF';
  this.linea.Status = 0;
  this.image = '';
  this.titulo = '';
  this.$v.$reset();
}
function handleFileUpload(image) {
  //this.image = '';
  this.linea.LineRoute = this.$refs.imageInput.file;
}
function statusConfirmation(){
  let status = this.lineaData.FgActShippingLine?1:0;
  if(this.linea.Status === status)
    this.submitFile();
  else{
    this.$swal.fire({
      text: `${this.$t('label.changeStatusQuestion')} ${this.lineaData.ShippingLineName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t('button.confirm'),
      cancelButtonText: this.$t('button.cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitFile();
      }
    });
  }
}

//Computeds:
function statusOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.ACTIVO')
    },
    {
      value: 0,
      label: this.$t('label.INACTIVO')
    }
  ]
}
function getTitulo() {
  return this.titulo;
}
function statusSelectColor(){
  return this.linea.Status === 1;
}

export default {
  name: 'edit-modal',
  mixins: [LineaNavieraMixin],
  props: {
    modal: Boolean,
    lineaData: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    PictureInput
  },
  data,
  validations: LineaValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    handleFileUpload,
    setLineaData,
    statusConfirmation,
    submitFile
  },
  computed: {
    statusOptions,
    getTitulo,
    statusSelectColor
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    },
    lineaData: function(newLinea) {
      this.setLineaData(newLinea);
    }
  }
}
</script>
<style lang="scss">
.preview {
 /* height: 15em;*/
  color: white;
  text-shadow:
      3px 3px 0 #000,
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
      1px 1px 0 #000;
}

.modal-content-linea {
  .modal-content {
    width: 80% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
      padding-bottom: 0;
    }
  }
}
.card{
  border:0;
}
</style>